import {
  authenticate,
  getSelf,
  register,
  googleConnect,
  generateBusinessMicrosoftUrl,
  generatePersonalMicrosoftUrl,
  generatePersonalMicrosoftReauthUrl,
  generatePersonalGoogleReauthUrl,
  generateDropboxUrl,
  generateBoxUrl,
} from "@/services/api/app/auth";
import Store from "@/store/app/index";
let ENVIRONMENT = 'staging';
if(process.env.VUE_APP_ENVIRONMENT) {
  ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;
}

const defaultState = () => ({
  self: {},
  isLoading: true,
  accessToken: null,
  googleTenant: {}
})

export default {
  namespaced: true,

  actions: {
    getSelf({commit}) {
      return getSelf()
        .then(user => {
          commit('setSelf', user);
          return user;
        })
        .catch(() => {
          commit('resetState')
          return Promise.reject();
        })
    },
    register({commit}, registerForm) {
      return register(registerForm)
        .then((result) => {
          commit('setAccessToken', result.accessToken)
        })
    },
    authenticate({commit}, authenticateForm) {
      return authenticate(authenticateForm)
        .then(result => {
          commit('setAccessToken', result.accessToken)
          return result
        })
    },
    generateBusinessMicrosoftUrl({commit, dispatch}) {
      return generateBusinessMicrosoftUrl();
    },

    generatePersonalMicrosoftUrl({commit, dispatch}) {
      return generatePersonalMicrosoftUrl();
    },
    generatePersonalMicrosoftReauthUrl({commit, dispatch}) {
      return generatePersonalMicrosoftReauthUrl();
    },

    generatePersonalGoogleReauthUrl({commit, dispatch}) {
      return generatePersonalGoogleReauthUrl();
    },
    googleConnect({commit, dispatch}, data) {
      return googleConnect(data)
        .then(() => {
          return dispatch('getSelf')
        })
    },

    generateDropboxUrl({commit, dispatch}, levelType) {
      return generateDropboxUrl(levelType);
    },

    generateBoxUrl({commit, dispatch}, levelType) {
      return generateBoxUrl(levelType);
    },

    signOut({commit, state}, redirectEnabled = true) {
      if(ENVIRONMENT === 'production' && redirectEnabled) {
        if(state.self?.platform === "360") {
          window.location.href = "https://webapp.idrive360.com/api/v1/logout"
        } else if(state.self?.platform === "rpc") {
          window.location.href = "https://login.remotepc.com/rpcnew/logout"
        } else if(state.self?.platform === "rpc") {
          window.location.href = "https://login.remotepc.com/rpcnew/logout"
        } else if(state.self?.platform === "ibackup") {
          window.location.href = "https://www.ibackup.com/newibackup/logout"
        } else {
          window.location.href = 'https://www.idrive.com/idrive/logout'
        }
      } else {
        commit('resetState')
      }
    },
  },

  mutations: {
    setSelf(state, user) {
      state.self = user
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
    getGoogleTenant(state, googleTenant) {
      state.googleTenant = googleTenant
    },
  },

  getters: {
    isMicrosoftProviderEnabled: (state) => {
      return !!state.self?.providerTenants?.find(providerTenant => providerTenant.providerTenantType === 1);
    },
    isGoogleProviderEnabled: (state) => {
      return !!state.self?.providerTenants?.find(providerTenant => providerTenant.providerTenantType === 2);
    },
    isDropboxProviderEnabled: (state) => {
      return !!state.self?.providerTenants?.find(providerTenant => providerTenant.providerTenantType === 3);
    },
    isBoxProviderEnabled: (state) => {
      return !!state.self?.providerTenants?.find(providerTenant => providerTenant.providerTenantType === 4);
    },
    isProviderConnected: (state) => (providerType) => {
      return !!state.self?.providerTenants?.find(providerTenant => providerTenant.providerTenantType === providerType);
    },
    isSuperAdmin: (state) => {
      return state.self.roles?.find(role => role.name === 'SuperAdmin')
    },
    accessLevel: (state) => {
      return  state.self?.accessLevel;
    },
    providerLevelType: (state) => {
      if (Store.state.providerTenant === 'GOOGLE') {
        return state.self?.providerTenants?.find(providerTenant => providerTenant?.providerTenantType === 2)?.levelType;
      } else if (Store.state.providerTenant === 'MICROSOFT') {
        return state.self?.providerTenants?.find(providerTenant => providerTenant?.providerTenantType === 1)?.levelType;
      } else if (Store.state.providerTenant === 'DROPBOX') {
        return state.self?.providerTenants?.find(providerTenant => providerTenant?.providerTenantType === 3)?.levelType;
      } else if (Store.state.providerTenant === 'BOX') {
        return state.self?.providerTenants?.find(providerTenant => providerTenant?.providerTenantType === 4)?.levelType;
      }
    }

  },

  state: defaultState()
}
